.App {
  width: 600px;
  border: 15px  solid blue;
  padding: 50px;
  margin: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}